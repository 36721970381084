* {
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
}
body {
    background-color: rgb(54,57,63);
}

.App {
    display: flex;
    flex-direction: row;
    background-color: rgb(54,57,63);
    overflow: hidden;
}
.navBar {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    height: 100vh;
    background-color: rgb(47,49,54);
    color: white;
    margin-left: 65px;
    position: fixed;
    overflow-y: auto;
}

.navBar > ul {
    list-style: none;
    display: flex;
    min-height: 100px;
    height: 20%;
    flex-direction: column;
    align-items: center;
    margin: 0px;
    padding: 7% 0px;
    font-size: 12.5px;
    z-index: 1;
    overflow-y: auto;
}

.navBar > div {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 45px;
    box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, .8);
}

.link {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10%;
    height: 40px;
    width: 80%;
    color: lightgrey;
    text-decoration: none;
    border-radius: 3px;
    font-weight: bold;
    letter-spacing: .05em;
}

.active {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10%;
    height: 40px;
    width: 80%;
    color: white;
    font-weight: bold;
    text-decoration: none;
    border-radius: 3px;
    letter-spacing: .05em;
    background-color: rgb(54,57,63);
}

.links {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
    height: 100vh;
    background-color: rgb(32,34,37);
    position: fixed;
    overflow-y: auto;
}

.links > ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.links-head {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.links-head > div {
    min-height: 45px;
    min-width: 45px;
    border-radius: 35%; 
    background-color: rgb(114, 137, 218);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.link > svg {
    height: 25px;
    width: 25px;
    margin-right: 10%;
}

div > svg {
    height: 25px;
    width: 25px;
    text-shadow:1px 1px 2px black;
}

.contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: 10px;
}

.contacts > li > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    width: 65px;
}

.contacts > li > a > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 70%;
    border-radius: 50%; 
    background-color: rgb(114, 137, 218);
}

.contacts > li > a > div:hover {
    animation: round .5s ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes round {
    0% {border-radius:50%;}
    100% {border-radius:35%;}
}

.footer {
    display: flex;
    margin-bottom: 0;
    height: 70px;
    background-color: rgb(41,43,47);
    font-size: 12px;
    color:grey;
    font-weight: bold;
}

.footer > .chat {
    padding: 0;
    border: none;
}

.footer > .chat > .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer > .chat > .icon-container > .chatIcon {
    height: 35px;
    width: 35px;
}

.user {
    margin-top: 15px;
    color: white;
    font-size: 15px;
}

#skillz {
    height: calc(100% - 100px);
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow-y: auto;
}

#skillz > div {
    display: flex;
    min-height: 20px;
    padding-left: 10%;
    font-size: 14px;
    width: 80%;
    color:lightgrey;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: .25em;
}

#skillz > ul {
    list-style: none;
    list-style: none;
    display: flex;
    height: 20%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 0px;
    padding: 7% 0px;
    font-size: 12.5px;
    z-index: 1;
}

#skillz > ul > li {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 40px;
    flex-shrink: 0;
}
#skillz > ul > li > div {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 40px;
    padding-left: 10%;
    width: 80%;
    color: lightgrey;
    text-decoration: none;
    border-radius: 3px;
    font-weight: bold;
    letter-spacing: .07em;
}

#skillz > ul > li > div:hover {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10%;
    height: 40px;
    width: 80%;
    color: white;
    font-weight: bold;
    text-decoration: none;
    border-radius: 3px;
    letter-spacing: .07em;
    background-color: rgb(54,57,63);
}